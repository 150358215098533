<template>
  <div class="legendItem">
    <div v-if="item.type === 'box'" :style="item.style"></div>
    <div v-if="item.type === 'dash'" :style="item.style"></div>
    <div class="image" v-if="item.type === 'image'" :style="item.style">
      <v-img :src="`/assets/legend/${item.url}`"></v-img>
    </div>
  </div>
</template>
<script>
export default {
  name: "mapLegend",

  props: {
    item: Object
  },

  computed: {},
  data: () => ({}),
  methods: {}
};
</script>

<style scoped>
.legendItem {
  height: 22px;
  width: 22px;
  overflow: visible;
}
.legendItem > div {
  height: 22px;
  width: 22px;
}

.legendItem > div.image {
  height: 22px;
  width: 30px;
}
</style>
