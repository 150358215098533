<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-show="false"
          v-bind="attrs"
          v-on="on"
          class=" "
          @click="scrollToSiteDelay()"
        >
          Layer Info
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            color: appSettings.mainMenuBarColor + '!important',
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Future Geelong Maptivate Layers
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="LayerInfoContentWrapper"
            @scroll="onScroll"
          >
            <center>
              <v-card-title id="aboutHeading_live" class="justify-center"
                >LIVE
              </v-card-title>
              <v-card-text>
                Future Geelong will respond to substantial population growth
                trends and a diversifying demographic profile by delivering
                residential development in accordance with established policy
                settings.
              </v-card-text>
              <v-card-text>
                Much of Geelong's projected residential growth has been
                accommodated in Central Geelong, as well as new (NWGGA) and
                legacy (Armstrong Creek) growth areas. Robust strategic planning
                will be undertaken to provide clearer expectations for infill
                residential growth in Geelong's established suburbs and
                townships.
              </v-card-text>
              <v-card-text>
                Geelong's existing and projected growth dictates that our
                community will need a diverse range of living locations and
                options. While Central Geelong and other middle-ring suburbs
                will provide a significant proportion of the required supply,
                there is a need to deliver some large scale urban renewal
                precincts, and potential future greenfield investigation areas.
                It is critical that strategic planning for these precincts is
                undertaken early to ensure broader strategic decisions around
                transport corridors and density targets have regard for the
                precincts.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title id="aboutHeading_move" class="justify-center"
                >MOVE
              </v-card-title>
              <v-card-text>
                Future Geelong's historic and geographic strengths as a
                significant port city in Victoria will be leveraged. Avalon
                Airport and the Port of Geelong perform an enhanced transport
                and logistics role, and are Geelong’s domestic and international
                gateways.
              </v-card-text>
              <v-card-text>
                Existing collector rail infrastructure along the Geelong line
                between Lara and Winchelsea Railway Stations will be leveraged.
              </v-card-text>
              <v-card-text>
                The rail infrastructure between Bannockburn and Geelong will be
                revived for passenger services to support substantial population
                growth anticipated for the Western Geelong Growth Area and
                Bannockburn.
              </v-card-text>
              <v-card-text>
                Geelong's existing suburban bus network will be overhauled to
                provide fine grain connectivity between and around Central
                Geelong, the 'transit villages' and other suburban and township
                parts of the region. Existing efforts to deliver continuous and
                connected active transport infrastructure will be consolidated.
                These public and active transport interventions will
                significantly reduce the extent of 'last mile' journeys and
                short trips being taken in private vehicles.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title id="aboutHeading_play" class="justify-center"
                >PLAY
              </v-card-title>

              <v-card-text>
                Geelong's coastal and riverine waterways are enhanced as sites
                of leisure and amenity. Geelong's unique location between Corio
                Bay, Port Phillip, the Bellarine Peninsula, the Surf Coast and
                Barwon and Moorabool Rivers provides the basis for the lifestyle
                benefits that have already attracted a booming population to the
                City Region.
              </v-card-text>
              <v-card-text>
                Geelong’s Cultural Precinct and Kardinia Park are integral parts
                of the Geelong community and major drawcard for the area for
                events and cultural occasions.
              </v-card-text>
              <v-card-text>
                Unique, internationally significant projects such as CORA and
                the Eden Project, Anglesea are global destinations that further
                cement the Geelong city region's place on the map.
              </v-card-text>
              <v-card-text>
                Geelong's suburban centres are vibrant and host a variety of
                local attractions, services and businesses.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title id="aboutHeading_work" class="justify-center"
                >WORK
              </v-card-title>
              <v-card-text>
                Future Geelong's concentration of activities in advanced
                manufacturing and the social insurance sector provides economic
                specialisation that bolsters the region's productivity, growth
                and competitiveness.
              </v-card-text>
              <v-card-text>
                Geelong's economy continues to encourage diversity as a key
                determinant of resilience. Geelong's diversified economic base
                is not reliant on a single industry, contains industries with
                different sensitivities to economic downturns, changes in export
                markets and shifts in terms of trade functions to spread risks
                to different types of activities.
              </v-card-text>
              <v-card-text>
                These sectors not only contribute to Geelong's local employment
                base, but support the amenities, services and lifestyle
                attributes that existing residents value and new residents
                desire. Geelong's health care sector also services a significant
                catchment in south-west Victoria.
              </v-card-text>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </center>
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              :color="appSettings.dialogActionButtonColor"
              text
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "LayerInfoModal",

  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "LayerInfo"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadLayerInfoDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `aboutHeading_live`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById(
        "LayerInfoContentWrapper"
      );
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
