<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class=" " @click="scrollToSiteDelay()">
          About
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            background: 'white'
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Welcome to 'Future Geelong'
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="AboutContentWrapper"
            @scroll="onScroll"
          >
            <center>
              <v-card-title
                v-if="false"
                id="aboutHeading_top"
                class="justify-center"
                >Welcome to 'Future Geelong'
              </v-card-title>

              <v-img
                id="aboutHeading_top"
                style="max-width:50%;    border-radius: 4px;"
                class="pa-0 ma-0"
                src="/assets/landingPages/CFG_Logo_Purple_small.png"
              />
              <br>
              <v-card-text>
                Future Geelong captures the Committee for Geelong's (CfG)
                prospectus for the transformative changes necessary to support a
                thriving and resilient Geelong city region at a population of 1
                million people.
              </v-card-text>
              <v-card-text>
                Future Geelong amalgamates the visionary thinking of the CfG
                membership base, and the wealth of existing strategic work which
                demonstrates that Geelong’s population, infrastructure, location
                and economic structure provide a strong basis to attract
                investment activity by government and industry.
              </v-card-text>
              <v-card-text>
                The essence of the Future Geelong collective vision is distilled
                into four key domains: <strong>LIVE, MOVE, WORK</strong> and
                <strong>PLAY.</strong> These domains are intrinsically linked
                and are conceptually framed to capture the fine-grain, lived
                experience of Future Geelong.
              </v-card-text>
              <v-card-text>
                The corresponding map <strong>layers</strong> collate a series
                of <strong>map points</strong> which describe projects,
                interventions and concepts collated under the four domains. The
                <strong>LIVE, MOVE, WORK</strong> and
                <strong>PLAY</strong> layers can be isolated or overlaid to
                provide an appreciation of both the overarching vision and
                component parts.
              </v-card-text>

            </center>
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              :color="appSettings.dialogActionButtonColor"
              text
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "./../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to Maptivate"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `aboutHeading_top`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("AboutContentWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  },
  mounted() {
    let bootOption = false;

    if (this?.appSettings?.allowSkipWelcomeDialog === true) {
      bootOption = localStorage.getItem("dontShowWelcome");
      if (bootOption == true || bootOption == "true") {
        bootOption = true;
      }
      if (bootOption === true) {
        this.dontShowAgain = true;
      }
    }

    if (bootOption != true) {
      this.dialog = true;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
