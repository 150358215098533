<template>
  <v-card elevation="6" x_shaped v-if="ready" class="pb-1">
    <v-carousel
      eager
      v-if="properties.media && properties.media.length"
      hide-delimiters
      :show-arrows="properties.media.length > 1"
      :height="'150px'"
    >
      <v-carousel-item
        v-for="(item, i) in properties.media"
        :key="i"
        :src="item.src_thumb"
      ></v-carousel-item>
    </v-carousel>

    <div class="titleBox  secondary--text">
      <v-card-title key="" v-if="properties.Point_Name">
        <engAndTrans :value="properties.Point_Name" />
      </v-card-title>
    </div>

    <PointDescriptionBody
      :functions="{ updateShowMore, openSidebarRemote }"
      type="popup"
      :Point_Description="Point_Description"
      :showMore="showMore"
    />

    <extraInfoBlocks
      :appSettings="appSettings"
      :properties="properties"
      :location="properties"
      :media="properties.media"
      :Point_Name="properties.Point_Name"
      :Site_Marker_Number="properties.Site_Marker_Number"
      :structuredData="properties.structuredData"
      :functions="{ openYoutube, setSound, getAudioDetails, pointSet }"
      :isPopup="true"
    />

    <v-card-actions
      class="pb-1 mb-0 pt-0 mt-1"
      v-if="$vuetify.breakpoint.mdAndDown || !appSettings.autoOpenMoreInfo"
    >
      <v-btn
        class="pb-0 mb-0 pt-0 mt-0"
        :color="'#a2c2cd'"
        :xcolor="appSettings.dialogActionButtonColor"
        @click="openSidebar(properties.index)"
        :width="appSettings.popupButtonWidth"
      >
        More Info
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        v-if="false"
        right
        :color="appSettings.dialogActionButtonColor"
        text
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { eventBus } from "../../main";

import Vue from "vue";

import extraInfoBlocks from "./../info/extraInfoBlocks.vue";
import engAndTrans from "./../ui/engAndTrans.vue";
import PointDescriptionBody from "./../ui/PointDescriptionBody.vue";

export default Vue.extend({
  components: {
    extraInfoBlocks,
    engAndTrans,
    PointDescriptionBody
  },
  data: () => ({
    showMore: false,
    ready: false,
    showCarousel: false,
    media: []
  }),

  props: {
    //goToPointByPointName: Function,
    getAudioDetails: Function,
    openYoutube: Function,
    setSound: Function,
    openSidebar: Function,
    clearAllPopups: Function,
    appSettings: Object,
    pointSet: Object,
    properties: {
      required: true,
      type: Object
    },
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    Point_Description() {
      return this.properties?.content?.rendered;
    },

    linkPoint() {
      var link = null;
      try {
        if (this.properties && this.properties.linkPoint) {
          link = this.properties.linkPoint;

          try {
            link = JSON.parse(link);
          } catch (error) {
            //do nothing;
          }
        }
      } catch (error) {
        //do nothing;
      }
      return link;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    if (this.browserDetectCopy) {
      this.$browserDetect = this.browserDetectCopy;
    }

    this.ready = true;
    this.showCarousel = true;

    setTimeout(() => {
      if (
        !this.$vuetify.breakpoint.mdAndDown &&
        this.appSettings.autoOpenMoreInfo
      ) {
        //not small!!!
        this.openSidebar(this.properties.index);
      }
    }, 500);
  },

  methods: {
    openSidebarRemote(alsoShowMore) {
      this.openSidebar(this.properties.index);

      if (alsoShowMore) {
        eventBus.$emit("pointDiscription_expand");
      }
    },

    updateShowMore(value) {
      this.showMore = value ? value : false;
    }
  }
});
</script>

<style>
.moreInfoButton {
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 30px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  width: 285px;
  max-width: 95vw;
}

.titleBox {
  xmargin-top: -6px;
  xmargin-bottom: -6px;
}

@media only screen and (max-width: 650px) {
  .galPopup .mapboxgl-popup-content {
    zoom: 75%;
  }

  .titleBox {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .audioBox {
    margin-bottom: -6px;
  }
}
</style>
