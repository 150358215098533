const jsonImageKey = "icon_id";

const genZoomSetps = function(scale = 16, step = 0.1) {
  var values = [0, 0];

  //var scale = 16;
  var zoom = 14;
  //  var step = 0.1;

  do {
    values.push(zoom);
    let newValue = Math.pow(2, zoom) * (scale / Math.pow(2, 23));
    values.push(newValue);
    zoom = zoom + step;
  } while (zoom <= 23);

  return values;
};

if (genZoomSetps) {
  //do nothing;
}

const mergeSettings = (layerSetup, layerSectionSetup) => {
  let layerOptions;
  try {
    if (layerSectionSetup?.options?.layerOptions) {
      layerOptions = layerSectionSetup.options.layerOptions;
      let section = "layout";
      if (layerOptions[section]) {
        for (let key in layerOptions[section]) {
          layerSetup[section][key] = layerOptions[section][key];
        }
      }
      section = "paint";
      if (layerOptions[section]) {
        for (let key in layerOptions[section]) {
          layerSetup[section][key] = layerOptions[section][key];
        }
      }
      section = "topLevel";
      for (let key in layerOptions[section]) {
        layerSetup[key] = layerOptions[section][key];
      }
    }
  } catch (error) {
    console.error("error merging layer settings,", layerOptions);
  }

  return layerSetup;
};

export default new (class {
  async addArtWorkLayer({
    parentThis,
    map,
    geoJson_artwork,
    categoryLookup,
    appSettings
  }) {
    if (geoJson_artwork?.data?.features?.length) {
      //this is to move the school house markers to the same location as thier parent pointers.

      //sort data by section;
      let geoJsonFeaturesBySection = {};
      let sectionSetupBySlug = {};
      geoJson_artwork?.data?.features.forEach(feature => {
        try {
          let section_slug = feature.properties.section_slug;

          if (!sectionSetupBySlug[section_slug]) {
            sectionSetupBySlug[section_slug] = categoryLookup[section_slug];
          }

          if (!geoJsonFeaturesBySection[section_slug]) {
            geoJsonFeaturesBySection[section_slug] = [];
          }

          geoJsonFeaturesBySection[section_slug].push(feature);
        } catch (error) {
          console.error("Error sorting artwork feature", error);
        }
      });

      let addedArtworkDataSourceId = [];
      let artworkLayer = {};

      for (let section_slug in geoJsonFeaturesBySection) {
        let layerData = geoJsonFeaturesBySection[section_slug];
        let layerSectionSetup = sectionSetupBySlug[section_slug];

        let dataSourceIdName = `artworkDataSource_${section_slug}`;
        let layerIdName = `artworkLayer_${section_slug}`;

        let customLayerSetup = {};

        if (layerData.length) {
          try {
            let geoJsonData = {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                name: dataSourceIdName,
                features: layerData
              },
              ...customLayerSetup
            };

            parentThis.dataSouceIds[dataSourceIdName] = dataSourceIdName;
            parentThis.filterableDataSouceIds[
              dataSourceIdName
            ] = dataSourceIdName;

            map.addSource(dataSourceIdName, geoJsonData);
            addedArtworkDataSourceId.push(dataSourceIdName);

            let layerSetup = {
              minzoom: appSettings.minZoom.secondary,
              id: layerIdName,
              type: "symbol",
              source: dataSourceIdName,
              paint: {},
              layout: {
                "icon-anchor": "bottom",
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,

                "icon-image": ["get", `${jsonImageKey}`],

                "icon-size": 1
              }
            };

            layerSetup = mergeSettings(layerSetup, layerSectionSetup);

            artworkLayer[layerIdName] = {
              layerIdName,
              dataSourceIdName,
              geoJsonData,
              layerSectionSetup
            };
            parentThis.pointLayerIds[layerIdName] = layerIdName;

            let fillSitUnder = appSettings?.layersUnder?.art;
            map.addLayer({ ...layerSetup, before: fillSitUnder }, fillSitUnder);
          } catch (error) {
            //do nothing
          }
        }
      }
    }
  }
})();
