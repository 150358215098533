import _ from "lodash";

const getZoomScaleStops = function(factor) {
  return [
    [13, factor * 1],
    [15, factor * 2],
    [17, factor * 5],
    [18, factor * 7],
    [20, factor * 14]
  ];

  //  const pinFolder = "actBlob";
  //  const pinScale = 2;

  /*
  return [
    [13, factor * 3],
    [14, factor * 6],
    [15, factor * 8],
    [16, factor * 10],
    [20, factor * 12]
  ];
  */
};

export default new (class {
  async createWpmarkerImages(map, markerImagesn, categoryLookup) {
    //this dynamicly loads 60 images for each of the project marker

    let imageList = _.values(markerImagesn);

    var N = 60;
    try {
      N = imageList.length;
      //N = markerList.data.features.length + 1;
    } catch (error) {
      N = 60;
    }

    const arr = [...Array(N + 1).keys()].slice(1);

    // this creates a blank image while the icons load.
    var width = 1;
    var bytesPerPixel = 4;
    var blankImage = new Uint8Array(width * width * bytesPerPixel);
    for (var x = 0; x < width; x++) {
      for (var y = 0; y < width; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        blankImage[offset + 0] = 1; // red
        blankImage[offset + 1] = 1; // green
        blankImage[offset + 2] = 1; // blue
        blankImage[offset + 3] = 0; // alpha
      }
    }

    var genIcon = async function(map, blankImage, width, imageObj) {
      var pixelRatio = 2;
      let imageId = String(imageObj.id);
      let imageHref = (imageHref = imageObj.marker
        ? imageObj.marker
        : imageObj.href);

      //for artwork layer can have scale factor and use larger image;
      if (imageObj.isArtwork === true && imageObj.marker_lg) {
        imageHref = imageObj.marker_lg;
        pixelRatio = 4;

        let useMedium = false;
        if (useMedium === true) {
          imageHref = imageObj.marker_md;

          console.log(imageObj);
          pixelRatio = 4 / 1.6;
        }

        if (imageObj.scale_factor && !isNaN(imageObj.scale_factor)) {
          pixelRatio = pixelRatio / imageObj.scale_factor;
        }
        if (
          imageObj.section_slug &&
          categoryLookup[imageObj.section_slug] &&
          !isNaN(
            categoryLookup[imageObj.section_slug]?.options?.imageSettings
              ?.section_artwork_scale_factor
          )
        ) {
          let sectionScaleFactor =
            categoryLookup[imageObj.section_slug].options.imageSettings
              .section_artwork_scale_factor;
          pixelRatio = pixelRatio / sectionScaleFactor;
        }
      }

      map.addImage(imageId, {
        width: width,
        height: width,
        data: blankImage
      });

      await map.loadImage(
        `${imageHref}`,
        //imageHref,
        async (error, image) => {
          if (error) throw error;
          map.removeImage(imageId); //remove dummy image
          //add loaded image
          map.addImage(imageId, image, { pixelRatio: pixelRatio });
        }
      );
    };

    //genIcon(map, blankImage, width, imageId);

    const loadNumberIconsLoop = async () => {
      for (let index = 0; index < arr.length; index++) {
        // Get num of each fruit
        //let imageId = "icon" + arr[index];

        let imageObj = imageList[index];
        genIcon(map, blankImage, width, imageObj);
      }
    };

    await loadNumberIconsLoop();
  }

  renderMarkersCircles(map, appSettings) {
    // the marker icon:
    const pinScale = 1; //;2;

    let useIcons = true;
    let stacking = true;

    let stackingfilterIcons = {};
    //let stackingfilterStack = {};
    if (stacking) {
      stackingfilterIcons.filter = ["==", "hasStacking", false];
      //stackingfilterStack = {};
    }

    if (useIcons) {
      map.addLayer({
        ...stackingfilterIcons,
        id: "places",
        type: "symbol",
        source: "places",

        minzoom: appSettings.minZoom.points,

        paint: {
          "text-color": appSettings.styles.markerText,
          //"icon-opacity": 0.95,
          "icon-opacity": [
            "match",
            ["get", "hideMarkerButMakeClickable"],
            "true",
            0.01,
            1 // fallback value
          ],

          "text-opacity": {
            stops: [
              [12, 0],
              [13, 1]
            ]
          }
        },
        layout: {
          "text-field": appSettings.showSiteNumbers_Labels
            ? ["get", "Site_Marker_Number_Label"]
            : "",

          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: getZoomScaleStops(2)
          },
          //"text-offset": [0, -0.4],
          "text-anchor": "bottom",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": false,
          "text-ignore-placement": true,
          "icon-image": ["get", "icon_id"],

          "icon-size": {
            stops: getZoomScaleStops(0.08 / pinScale)
          }
        }
      });
    }

    if (stacking) {
      map.addLayer({
        id: "places-stackedCopy",
        type: "symbol",
        source: "places",
        minzoom: appSettings.minZoom.points,
        //filter: [">", "stackIndex", 0],
        filter: ["==", "hasStacking", true],

        paint: {
          "icon-opacity": 0.9,
          "text-color": appSettings.styles.markerText,
          "text-opacity": {
            stops: [
              [12, 0],
              [13, 1]
            ]
          }
        },
        layout: {
          "text-field": appSettings.showSiteNumbers_ClusterLabels
            ? ["get", "stackedIndexLabel"]
            : "",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: getZoomScaleStops(1.5)
          },
          "text-offset": [0, -0.4],
          "text-anchor": "bottom",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          //"icon-allow-overlap": false,
          //"icon-anchor": "bottom",
          //"icon-offset":"bottom",
          "icon-image": ["get", "icon_id"],
          /*
          "icon-image": [
            "match",
            ["get", "section_slug"],
            "Prospective Families",
            "prospective_marker",
            "Wayfinding",
            "wayfinding_marker",
            "History",
            "history_marker",
            "prospective_marker"
          ],
*/
          //"icon-size": 0.25,
          //"icon-rotate": 180,
          "icon-size": {
            stops: getZoomScaleStops(0.08 / pinScale)

            /*
            [
              [13, 0.08 * 3],
              [14, 0.08 * 6],
              [15, 0.08 * 8],
              [16, 0.08 * 10],
              [20, 0.08 * 12] //30
            ]
            */
          },

          "icon-offset": ["get", "stackOffset"]
        }
      });
    }
  }
})();
