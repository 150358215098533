import _ from "lodash";
import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      mapStyleExtraFilters: state => state.filters.mapStyleExtraFilters
    })
  },

  methods: {
    updatefilters(filters) {
      let mapStyleKeys = _.keys(this.mapStyleExtraFilters);
      let mapStyleFilters = filters.filter(row => mapStyleKeys.includes(row));
      filters = filters.filter(row => !mapStyleKeys.includes(row));

      mapStyleKeys.forEach(layer => {
        let layerId = layer.replace("basemapStyle_", "");
        let show = false;
        let hasLinkedLayer = this.mapStyleExtraFilters[layer]
          ?.linkedToMakerLayer;

        if (mapStyleFilters.includes(layer)) {
          show = true;
        }

        if (filters.includes(hasLinkedLayer)) {
          show = true;
        }

        if (show === true) {
          //show layer;
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        } else {
          //hide layer;
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      });

      /*
      if (this.layerState) {
        // Hide a layer
        this.map.setLayoutProperty(
          "principal-bicycle-network-pbn-01xxx",
          "visibility",
          "none"
        );
        this.map.setLayoutProperty(
          "vic-o-transport-routes-bus-01",
          "visibility",
          "none"
        );
      } else {
        // Show a hidden layer
        this.map.setLayoutProperty(
          "principal-bicycle-network-pbn-01",
          "visibility",
          "visible"
        );
        this.map.setLayoutProperty(
          "vic-o-transport-routes-bus-01",
          "visibility",
          "visible"
        );
      }
*/
      //markerLayerFilter;
      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        let thisSourceData = this.map.getSource(source)._data;
        thisSourceData.features.forEach(feature => {
          if (filters.includes(feature.properties.section_slug)) {
            feature.properties.hide = false;

            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [
                feature.properties.Latitude,
                feature.properties.Longitude
              ];
            }
          } else {
            feature.properties.hide = true;
            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [0, 0];
            }
          }

          if (
            feature.properties.isGeomLinePolyLayer &&
            feature.properties.section_slug == "Art_zoom1"
          ) {
            feature.properties.hide = false;
          }

          if (feature.properties.isGeomLinePolyLayer) {
            //console.log(feature.properties);
          }
        });

        this.map.getSource(source).setData(thisSourceData);
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
