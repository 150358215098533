//const train_line_new = require("./move/train_line_new.json");
var train_line_current = require("./move/train_line_current.json");

train_line_current.features.forEach((line, index) => {
  let { properties, geometry } = line;
  if (properties?.Style_Line_Colour === "red") {
    train_line_current.features[
      index
    ].geometry.coordinates = geometry.coordinates.reverse();
  }
});

//#9bd3a3
//const geelongOutline_Simple = require("./core/geelongOutline_Simple.json");

const train_line_Bannockburn_Lethbridge = require("./move/train_line_Bannockburn_Lethbridge.json");
const train_line_Bannockburn_NorthGeelong = require("./move/train_line_Bannockburn_NorthGeelong.json");
const train_line_airport = require("./move/train_line_airport.json");

const ArtworkFerryRouts = require("./move/ArtworkFerryRouts.json");
const ArtworkShipping = require("./move/ArtworkShipping.json");
//const bus_route_new = require("./move/bus_route_new.json");

const avalon_airport_rail_link_reservation = require("./move/Avalon_Airport_Rail_Link_Reservation.json");
const avalon_airport_zone_areas = require("./move/Avalon_Airport_SUZ11.json");

const Urban_renewal_28_Slevin_Street_North_Geelong = require("./work/Urban_renewal_28_Slevin_Street_North_Geelong.json");
const Urban_renewal_North_Geelong = require("./work/Urban_renewal.json");
const Urban_renewal_Moolap = require("./work/URBAN_RENEWAL_MOOLAP.json");

const port_zone_areas = JSON.parse(
  JSON.stringify(require("./move/Port_Zone_Areas.json"))
);
const port_precinct_work = JSON.parse(
  JSON.stringify(require("./move/Port_Zone_Areas.json"))
);

const MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA = require("./move/MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA.json");
const MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA = require("./move/MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA.json");
const MOVE_UNDER_THE_BAY = require("./move/MOVE_UNDER_THE_BAY.json");

const Avalon_employment_precinct_area = require("./work/Greater_Avalon_Employment_Precinct.json");
const Geelong_FutureEconomy_Precinct = require("./work/Geelong_FutureEconomy_Precinct.json");

const Breakwater_Precinct = require("./live/Breakwater_Precinct.json");
const Moolap_Point_Henry_Precinct = require("./live/Moolap_Point_Henry_Precinct.json");
const Bellarine_Peninsula_Townships = require("./live/Bellarine_Peninsula_Townships.json");

const Western_Geelong_Growth_Area = require("./live/Western_Geelong_Growth_Area.json");
const Northern_Geelong_Growth_Area = require("./live/Northern_Geelong_Growth_Area.json");

const established_suburbs_and_townships = require("./live/established_suburbs_and_townships.json");

const Armstrong_Creek_Growth_Area = require("./live/Armstrong_Creek_Growth_Area.json");

const LIVE_BORAL_LAND = require("./live/LIVE_BORAL_LAND.json");

const Central_Geelong_live = JSON.parse(
  JSON.stringify(require("./live/Central_Geelong.json"))
);
const Central_Geelong_work = JSON.parse(
  JSON.stringify(require("./live/Central_Geelong.json"))
);

const work_Existing_Employment = require("./work/Existing_Employment.json");

const Armstrong_Boundary = require("./live/live_outlines/Armstrong_Boundary.json");
const Bellarine_Peninsula_Townships_Boundary = require("./live/live_outlines/Bellarine_Peninsula_Townships_Boundary.json");
const Geelong_established_suburbs_Boundary = require("./live/live_outlines/Geelong_established_suburbs_Boundary.json");

const ARTWORK_GREENSPACE = require("./art/ARTWORKGREENSPACE.json");

/*
SQL for work_Existing_Employment
SELECT (the_geom) FROM onemapsecondary.vic_o_plan_zone where (  qzone_code ~ ', C1Z.*,|, B1Z.*,|, B2Z.*,|, C2Z.*,|, B3Z.*,|, B4Z.*,|, B5Z.*,|, IN1Z.*,|, IN2Z.*,|, IN3Z.*,' )
and
   ST_Intersects("onemapsecondary".vic_o_plan_zone.the_geom ,ST_SetSRID(ST_MakePolygon(ST_GeomFromText('LINESTRING(144.2892837524414 -38.09998264736479,144.38026428222653 -38.10538590283809,144.42935943603516 -38.16263584058639,144.415283203125 -38.19448191395686,144.30747985839844 -38.18449777869456,144.283447265625 -38.146707585003405,144.2892837524414 -38.09998264736479)')), 4326))
*/

/*
let principal_bicycle_network_pbn = require("./play/principal_bicycle_network_pbn.json");

let styles = {};
principal_bicycle_network_pbn.features.forEach(row => {
  let { type, status } = row.properties;

  row.properties.styleValue = `${type.replace(" ", "")}_${status.replace(" ", "")}`;

  styles[row.properties.styleValue] = row.properties.styleValue;
});
*/
//console.log(styles);
const colors = {
  live: "#d97065",
  move: "#F3E64F",
  play: "#2bac96",
  //work: "#3d2969",
  work: "#9587cd",
  workUr: "#9587cd",
  workExisting: "#d8d7eb",
  workUrOpacity: 1,
  liveExistingOutlines: "#d97065", //"#ace6ea",
  liveExistingOutlinesOpacity: 0.5,
  liveExisting: "#9ccdd2", //"#ace6ea",
  liveProposed: "#d5afaa",
  liveExistingOpacity: 0.6,
  liveProposedOpacity: 0.9,
  genOpacity: 0.7,
  railCurrent: "#F3E64F",
  railNew: "#ff8400",
  railNull: "#fffde7",
  newRaodColor: "yellow"
};

let trainLineZoom = ["step", ["zoom"], 0.3, 8,  0.6,11, 1.5, 12, 2, 14, 4, 18, 6];
let roadLineZoom = ["step", ["zoom"], 0.3, 8,0.6, 11, 1.5, 12, 3.5, 14, 7, 18, 8];

let currentResOutline = ["step", ["zoom"], 0.3, 8,0.4, 11, 1, 12, 2, 14, 3, 18, 3];

let roadStyle = {
  "line-color": "#9ccdd2", //"#f3c33b", // Light Pink
  "line-width": roadLineZoom // Width of the line
};

let roadStyleDashed = {
  "line-color": colors.move, //"#f3c33b", // Light Pink
  "line-width": roadLineZoom, // Width of the line
  "line-dasharray": [2, 2]
};

let trainAnimationZoom = 12;

var animationRunning = false;

export default new (class {
  async addLayers({ parentThis, map, appSettings }) {
    const fillSitUnder = appSettings?.layersUnder?.fill; //"tunnel-steps";
    const linesSitUnder = appSettings?.layersUnder?.lines; //"tunnel-steps";

    if (parentThis && map && appSettings) {
      //do nothing;
    }

    let layerSetup = {
      Bellarine_Peninsula_Townships_Boundary: {
        maptivateLayerSlug: "live",
        title: "Bellarine_Peninsula_Townships_Boundary",
        geoJson: Bellarine_Peninsula_Townships_Boundary,
        section: "precinct",
        geomType: "outline",
        subSection: "Bellarine_Peninsula_Townships_Boundary",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.liveExistingOutlines,
            "line-opacity": colors.liveExistingOutlinesOpacity,
            "line-width": currentResOutline
          }
        }
      },

      Geelong_established_suburbs_Boundary: {
        maptivateLayerSlug: "live",
        title: "Geelong_established_suburbs_Boundary",
        geoJson: Geelong_established_suburbs_Boundary,
        section: "precinct",
        geomType: "outline",
        subSection: "Geelong_established_suburbs_Boundary",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.liveExistingOutlines,
            "line-opacity": colors.liveExistingOutlinesOpacity,
            "line-width": currentResOutline
          }
        }
      },

      Armstrong_Boundary: {
        maptivateLayerSlug: "live",
        title: "Armstrong_Boundary",
        geoJson: Armstrong_Boundary,
        section: "precinct",
        geomType: "outline",
        subSection: "Armstrong_Boundary",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.liveExistingOutlines,
            "line-opacity": colors.liveExistingOutlinesOpacity,
            "line-width": currentResOutline
          }
        }
      },

      Central_Geelong_live: {
        maptivateLayerSlug: "live",
        title: "Central Geelong",
        geoJson: Central_Geelong_live,
        section: "precinct",
        geomType: "geom",
        subSection: "Central_Geelong_live",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed,
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Urban_renewal_Moolap: {
        maptivateLayerSlug: "work",
        title: "CSIRO AAHL Urban Renewal Area",
        geoJson: Urban_renewal_Moolap,
        section: "precinct",
        geomType: "geom",
        subSection: "Urban_renewal_Moolap",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.workUr, //"blue",
            "fill-opacity": colors.workUrOpacity
          }
        }
      },
      Urban_renewal_North_Geelong: {
        clickLoadSiteByTitle: "Saleyards Urban Renewal Area",
        maptivateLayerSlug: "live",
        title: "Saleyards Urban Renewal Area",
        geoJson: Urban_renewal_North_Geelong,
        section: "precinct",
        geomType: "geom",
        subSection: "Urban_renewal_North_Geelong",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },
      Urban_renewal_28_Slevin_Street_North_Geelong: {
        clickLoadSiteByTitle: "North Geelong Urban Renewal Area",

        maptivateLayerSlug: "live",
        title: "North Geelong Urban Renewal Area",
        geoJson: Urban_renewal_28_Slevin_Street_North_Geelong,
        section: "precinct",
        geomType: "geom",
        subSection: "Urban_renewal_28_Slevin_Street_North_Geelong",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Central_Geelong_work: {
        maptivateLayerSlug: "work",
        title: "Central Geelong",
        geoJson: Central_Geelong_work,
        section: "precinct",
        geomType: "geom",
        subSection: "Central_Geelong_work",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.work, //colors.live,
            "fill-opacity": colors.genOpacity
          }
        }
      },

      work_Existing_Employment: {
        maptivateLayerSlug: "work",
        title: "Existing Employment",
        geoJson: work_Existing_Employment,
        section: "precinct",
        geomType: "geom",
        subSection: "work_Existing_Employment",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.workExisting, //colors.live,
            "fill-opacity": 1
          }
        }
      },

      Armstrong_Creek_Growth_Area: {
        maptivateLayerSlug: "live",
        title: "Armstrong Creek Growth Area",
        geoJson: Armstrong_Creek_Growth_Area,
        section: "precinct",
        geomType: "geom",
        subSection: "Armstrong_Creek_Growth_Area",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveExisting, //colors.live,
            "fill-opacity": colors.liveExistingOpacity
          }
        }
      },

      established_suburbs_and_townships: {
        maptivateLayerSlug: "live",
        title: "Established Suburbs and Townships ",
        geoJson: established_suburbs_and_townships,
        section: "precinct",
        geomType: "geom",
        subSection: "established_suburbs_and_townships",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveExisting, //colors.live, //
            "fill-opacity": colors.liveExistingOpacity
          }
        }
      },

      LIVE_BORAL_LAND: {
        clickLoadSiteByTitle: "Waurn Ponds Greenfield Investigation Area",
        maptivateLayerSlug: "live",
        title: "Waurn Ponds Greenfield Investigation Area",
        geoJson: LIVE_BORAL_LAND,
        section: "precinct",
        geomType: "geom",
        subSection: "LIVE_BORAL_LAND",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Western_Geelong_Growth_Area: {
        clickLoadSiteByTitle: "Western Geelong Growth Area",
        maptivateLayerSlug: "live",
        title: "Western Geelong Growth Area",
        geoJson: Western_Geelong_Growth_Area,
        section: "precinct",
        geomType: "geom",
        subSection: "Western_Geelong_Growth_Area",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Northern_Geelong_Growth_Area: {
        clickLoadSiteByTitle: "Northern Geelong Growth Area",
        maptivateLayerSlug: "live",
        title: "Northern Geelong Growth Area ",
        geoJson: Northern_Geelong_Growth_Area,
        section: "precinct",
        geomType: "geom",
        subSection: "Northern_Geelong_Growth_Area",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Bellarine_Peninsula_Townships: {
        maptivateLayerSlug: "live",
        title: "Bellarine Peninsula Townships",
        geoJson: Bellarine_Peninsula_Townships,
        section: "precinct",
        geomType: "geom",
        clickLoadSiteByTitle: "Bellarine Peninsula Townships",
        subSection: "Bellarine_Peninsula_Townships",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveExisting, //colors.live, //
            "fill-opacity": colors.liveExistingOpacity
          }
        }
      },
      Breakwater_Precinct: {
        clickLoadSiteByTitle: "Breakwater Urban Renewal Precinct",
        maptivateLayerSlug: "live",
        title: "Breakwater Urban Renewal Precinct",
        geoJson: Breakwater_Precinct,
        section: "precinct",
        geomType: "geom",
        subSection: "Breakwater_Precinct",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },
      Moolap_Point_Henry_Precinct: {
        clickLoadSiteByTitle: "Moolap-Point Henry Precinct",
        maptivateLayerSlug: "live",
        title: "Moolap-Point Henry Precinct",
        geoJson: Moolap_Point_Henry_Precinct,
        section: "precinct",
        geomType: "geom",
        subSection: "Moolap_Point_Henry_Precinct",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.liveProposed, //colors.live, //"blue",
            "fill-opacity": colors.liveProposedOpacity
          }
        }
      },

      Geelong_FutureEconomy_Precinct: {
        clickLoadSiteByTitle: "Geelong Future Economy Precinct",
        maptivateLayerSlug: "work",
        title: "Geelong Future Economy Precinct",
        geoJson: Geelong_FutureEconomy_Precinct,
        section: "work",

        geomType: "geom",
        subSection: "Geelong_FutureEconomy_Precinct",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.work, //"#ff1100",
            "fill-opacity": colors.genOpacity
          }
        }
      },
      Avalon_employment_precinct_area: {
        maptivateLayerSlug: "work",
        title: "Greater Avalon Employment Precinct",

        geoJson: Avalon_employment_precinct_area,
        section: "airport",
        geomType: "geom",
        subSection: "Avalon_employment_precinct_area",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.work, //"#ff1100", // Tomato
            "fill-opacity": colors.genOpacity
          }
        }
      },
      avalon_airport_rail_link_reservation: {
        maptivateLayerSlug: "move",

        title: "Avalon Airport Rail Link Land Reservation",
        geoJson: avalon_airport_rail_link_reservation,
        section: "airport",
        geomType: "geom",
        subSection: "avalon_airport_rail_link_reservation",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.move, //"#FF6347", // Tomato
            "fill-opacity": colors.genOpacity
          }
        }
      },
      avalon_airport_zone_areas: {
        //        clickLoadSiteByTitle: "Avalon Airport",
        maptivateLayerSlug: "move",
        title: "Avalon Airport (Special Use Zone)",
        geoJson: avalon_airport_zone_areas,
        section: "airport",
        geomType: "geom",
        subSection: "avalon_airport_zone_areas",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.move, //"#ff9100", // Pale Green
            "fill-opacity": colors.genOpacity
          }
        }
      },
      port_precinct_work: {
        maptivateLayerSlug: "work",
        title: "Port Precinct",
        geoJson: port_precinct_work,
        section: "port",
        geomType: "geom",
        subSection: "port_precinct_work",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.work, // "#ff9800", // Hot Pink
            "fill-opacity": colors.genOpacity
          }
        }
      },
      port_zone_areas: {
        maptivateLayerSlug: "move",
        title: "Port Precinct",
        geoJson: port_zone_areas,
        section: "port",
        geomType: "geom",
        subSection: "port_zone_areas",
        style: {
          layout: {},
          paint: {
            "fill-color": colors.move, // "#ff9800", // Hot Pink
            "fill-opacity": colors.genOpacity
          }
        }
      },

      /*



"OffRoad_Existing"
,
"OffRoad_Proposed"
,
"OnRoad_Existing"
 or
"OnRoad_Proposed"

*/
      /*
      principal_bicycle_network_pbn: {
        maptivateLayerSlug: "play",
        title: "principal_bicycle_network_pbn",
        geoJson: principal_bicycle_network_pbn,
        section: "bikeLanes",
        geomType: "bikeLine",
        subSection: "principal_bicycle_network_pbn",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },

//        "type": "On Road",
//        "status": "Existing"

          paint: {
            //"line-color": "#fbe25b",
            'line-color': [
              'match',
              ['get', 'type'], // The property to match
              'On Road', 'hsl(63, 86%, 51%)',
              'hsl(247, 83%, 50%)' // Default color if no match is found
          ],
            
            "line-width": 5,
            "line-dasharray": [0, 4, 3]
          }
        }
      },
*/
      train_line_airport: {
        maptivateLayerSlug: "move",
        title: "train_line_airport",
        geoJson: train_line_airport,
        section: "train",
        geomType: "line",
        antlineDash: true,
        subSection: "train_line_airport",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.railNew,
            "line-width": trainLineZoom
          }
        }
      },
      train_line_Bannockburn_NorthGeelong: {
        maptivateLayerSlug: "move",
        title: "train_line_Bannockburn_NorthGeelong",
        geoJson: train_line_Bannockburn_NorthGeelong,
        section: "train",
        geomType: "line",
        antlineDash: true,
        subSection: "train_line_airport",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            //"line-color": "#fbe25b",
            "line-color": colors.railNew,
            "line-width": trainLineZoom
          }
        }
      },

      train_line_current: {
        maptivateLayerSlug: "move",
        title: "train_line_current",
        geoJson: train_line_current,
        section: "train",
        geomType: "line",
        antlineDash: true,
        subSection: "train_line_current",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": colors.railCurrent, //"#f3c33b", // Light Pink
            "line-width": trainLineZoom
          }
        }
      },

      train_line_Bannockburn_Lethbridge: {
        maptivateLayerSlug: "move",
        title: "train_line_Bannockburn_Lethbridge",
        geoJson: train_line_Bannockburn_Lethbridge,
        section: "train",
        geomType: "line",
        antlineDash: true,
        subSection: "train_line_Bannockburn_Lethbridge",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": colors.railNull, //"#f3c33b", // Light Pink
            "line-width": trainLineZoom
          }
        }
      },

      MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA: {
        maptivateLayerSlug: "move",
        title: "MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA",
        geoJson: MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA,
        section: "motorWay",
        geomType: "line",
        antlineDash: true,
        subSection: "MOVE_BELLARINE_LINK_CURRENT_PLANNING_AREA",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            ...roadStyle
          }
        }
      },

      MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA: {
        maptivateLayerSlug: "move",
        title: "MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA",
        geoJson: MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA,
        section: "motorWay",
        geomType: "line",
        antlineDash: true,
        subSection: "MOVE_BELLARINE_LINK_FUTURE_PLANNING_AREA",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            ...roadStyle
          }
        }
      },

      MOVE_UNDER_THE_BAY: {
        maptivateLayerSlug: "move",
        title: "MOVE_UNDER_THE_BAY",
        geoJson: MOVE_UNDER_THE_BAY,
        section: "motorWay",
        geomType: "line",
        antlineDash: true,
        subSection: "MOVE_UNDER_THE_BAY",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            ...roadStyle
          }
        }
      },
      ArtworkShipping: {
        maptivateLayerSlug: "Art_zoom1",
        title: "ArtworkShipping",
        geoJson: ArtworkShipping,
        section: "ferryARt",
        geomType: "line",
        subSection: "ArtworkShipping",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": "#2d4f63", //"#f3c33b", // Light Pink
            "line-width": roadLineZoom,
            "line-opacity": 0.3,

            "line-dasharray": [0, 3, 1]
          }
        }
      },
      ArtworkFerryRouts: {
        maptivateLayerSlug: "Art_zoom1",
        title: "ArtworkFerryRouts",
        geoJson: ArtworkFerryRouts,
        section: "ferryARt",
        geomType: "line",
        subSection: "ArtworkFerryRouts",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": "#2d4f63", //"#f3c33b", // Light Pink
            "line-width": roadLineZoom,
            "line-dasharray": [0, 3, 1]
          }
        }
      },
      ARTWORK_GREENSPACE: {
        maptivateLayerSlug: "Art_zoom1",
        title: "",
        geoJson: ARTWORK_GREENSPACE,
        section: "ARTWORK_GREENSPACE",
        geomType: "geom",
        subSection: "ARTWORK_GREENSPACE",
        style: {
          layout: {},
          paint: {
            "fill-color": "#b0dcc0", // "#ff9800", // Hot Pink
            "fill-opacity": 1
          }
        }
      }
      /*,

      geelongOutline_Simple: {
        maptivateLayerSlug: "art_zoom1",
        title: "geelongOutline_Simple",
        geoJson: geelongOutline_Simple,
        section: "outline",
        geomType: "line",
        antlineDash: true,
        subSection: "geelongOutline_Simple",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": "#f27092", //"#f3c33b", // Light Pink
            "line-width": roadLineZoom
          }
        }
      }
     ,
      vic_o_transport_routes_bus: {
        maptivateLayerSlug: "move",
        title: "vic_o_transport_routes_bus",
        geoJson: vic_o_transport_routes_bus,
        section: "bus",
        geomType: "line",
        subSection: "vic_o_transport_routes_bus",
        style: {
          layout: {
            "line-join": "round",
            "line-cap": "round"
          },
          paint: {
            "line-color": colors.move, //"#f3c33b", // Light Pink
            "line-width": 0,


            //"line-dasharray": [0, 4, 3]

          }
        }
      }
*/
    };

    const expandGeoJsonAndAddProps = ({
      title,
      clickLoadSiteByTitle,
      maptivateLayerSlug,
      geoJson,
      section,
      geomType,
      subSection
    }) => {
      geoJson.features.forEach(row => {
        row.properties.toolTipTitle = title;
        row.properties.section_slug = maptivateLayerSlug;
        row.properties.clickLoadSiteByTitle = clickLoadSiteByTitle;
        row.properties.hide = false;
        row.properties.isGeomLinePolyLayer = true;
        row.properties.subSection = subSection;
        row.properties.section = section;
        row.properties.geomType = geomType;
      });

      return geoJson.features;
    };

    let features = [];
    for (const [key, value] of Object.entries(layerSetup)) {
      if (key && value) {
        //
      }

      features = [...features, ...expandGeoJsonAndAddProps(value)];
    }

    parentThis.filterableDataSouceIds["mv_geomDataSource"] =
      "mv_geomDataSource";

    map.addSource("mv_geomDataSource", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...features]
      }
    });
    /*
    const pastelColors = [
      "#FFB6C1", // Light Pink
      "#FFD700", // Gold
      "#FFA07A", // Light Salmon
      "#87CEEB", // Sky Blue
      "#98FB98", // Pale Green
      "#9370DB", // Medium Purple
      "#F08080", // Light Coral
      "#20B2AA", // Light Sea Green
      "#FF69B4", // Hot Pink
      "#ADD8E6", // Light Blue
      "#FF6347", // Tomato
      "#00CED1", // Dark Turquoise
      "#FFDAB9", // Peachpuff
      "#66CDAA", // Medium Aquamarine
      "#BA55D3" // Medium Orchid
    ];
*/

    let dashArrayLayers = [];

    let index = 0;
    for (const [key, value] of Object.entries(layerSetup)) {
      let { style } = value;
      let { layout, paint } = style;

      const getFilter = subSection => {
        return [
          "all",
          ["==", ["get", "subSection"], subSection],
          ["==", ["get", "hide"], false]
        ];
      };

      if (value.geomType == "line") {
        map.addLayer(
          {
            id: `move_layer_${key}`,
            type: "line",
            source: "mv_geomDataSource",
            layout: {
              "line-join": "round",
              "line-cap": "round",
              ...layout
            },
            paint: {
              "line-width": 8,
              ...paint
            },
            filter: getFilter(value.subSection),
            before: linesSitUnder // Specify the layer name you want to be below
          },
          linesSitUnder
        );

        let layerId = `move_layer_${key}_dash`;

        if (value.section === "train") {
          if (value.antlineDash === true) {
            dashArrayLayers.push(layerId);
          }
          map.addLayer(
            {
              id: layerId,
              type: "line",
              source: "mv_geomDataSource",
              paint: {
                "line-color": "gray",
                "line-width": trainLineZoom,
                "line-dasharray": [0, 4, 3]
              },
              filter: getFilter(value.subSection),
              before: linesSitUnder // Specify the layer name you want to be below
            },
            linesSitUnder
          );
        }

        if (value.section === "motorWay") {
          if (value.antlineDash === true) {
            dashArrayLayers.push(layerId);
          }
          map.addLayer(
            {
              id: layerId,
              type: "line",
              source: "mv_geomDataSource",
              paint: { ...roadStyleDashed },
              filter: getFilter(value.subSection),
              before: linesSitUnder // Specify the layer name you want to be below
            },
            linesSitUnder
          );
        }
      }

      if (value.geomType == "outline") {
        let layerId = `move_layer_${key}`;

        map.addLayer(
          {
            id: layerId,
            type: "line",
            source: "mv_geomDataSource",
            layout: {
              "line-join": "round",
              "line-cap": "round",
              ...layout
            },
            paint: {
              ...paint
            },
            filter: getFilter(value.subSection),
            before: linesSitUnder // Specify the layer name you want to be below
          },
          linesSitUnder
        );
      }

      if (value.geomType == "geom") {
        let layerId = `move_layer_${key}`;

        map.addLayer(
          {
            id: layerId,
            type: "fill",
            source: "mv_geomDataSource",
            layout: {
              ...layout
            },
            paint: {
              //"fill-color": pastelColors[index],
              "fill-opacity": 0.4,
              ...paint
            },
            filter: getFilter(value.subSection),
            before: fillSitUnder // Specify the layer name you want to be below
          },
          fillSitUnder
        );

        parentThis.tooltipPointLayerIds[layerId] = layerId;
        parentThis.mobileClickTooltipPointLayerIds[layerId] = layerId;

        if (value.clickLoadSiteByTitle) {
          parentThis.geomClickLoadPopup[layerId] = layerId;
        }
      }

      if (index) {
        //do nothing;
      }

      index++;
    }

    //start of ant line animations block;
    var dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5]
    ];

    var factor = 10;
    dashArraySequence.forEach(row => {
      row.forEach((value, index) => {
        row[index] = factor * value;
      });
    });

    let step = 0;

    function animateDashArray(timestamp) {
      // Update line-dasharray using the next value in dashArraySequence. The
      // divisor in the expression `timestamp / 50` controls the animation speed.
      const newStep = parseInt((timestamp / 50) % dashArraySequence.length);

      if (newStep !== step) {
        if (map.getZoom() > trainAnimationZoom) {
          //animation line;
          animationRunning = true;
          dashArrayLayers.forEach(layerId => {
            map.setPaintProperty(
              layerId,
              "line-dasharray",
              dashArraySequence[step]
            );
          });
        } else {
          //reset dashes when animation is stopped due to out of zoom;
          if (animationRunning == true) {
            dashArrayLayers.forEach(layerId => {
              map.setPaintProperty(layerId, "line-dasharray", [0, 4, 3]);
            });
          }
          animationRunning = false;
        }

        step = newStep;
      }

      // Request the next frame of the animation.
      requestAnimationFrame(animateDashArray);
    }

    if (animateDashArray) {
      //do noting!!
    }
    // start the animation
    //animateDashArray(0);

    //end of ant line animations block;
  }
})();
