//import _ from "lodash";

const hideClusteredLayer = false;
/*
const getZoomScaleStops = function(factor) {
  return [
    [13, factor * 1],
    [15, factor * 2],
    [17, factor * 5],
    [18, factor * 7],
    [20, factor * 14]
  ];
};

*/

export default new (class {
  async createSignageLayer({
    parentThis,
    map,
    layerIdName,
    geoJson,
    appSettings,
    markerSections
  }) {
    //const layerIdName = "signage";
    //const pinScale = 1; //;2;

    let {
      hasPopup,
      clusterOptions,
      scaling,
      haveToolTip,
      hoverState,
      screenReaderHotspot,
      clusterLabel,
      clusterSetup,
      filter,
      styles,
      showLabels
    } = appSettings.layerDefaults[layerIdName];

    clusterSetup = { ...clusterSetup };

    if (markerSections) {
      let clusterProperties = {};
      for (const sectionKey in markerSections) {
        clusterProperties[sectionKey] = [
          "+",
          ["case", ["in", ["get", "section_slug"], sectionKey], 1, 0]
        ];
      }
      clusterSetup.clusterProperties = clusterProperties;

      clusterSetup.clusterProperties.marker_indexs = [
        "concat",
        ["concat", ["get", "index"], ","]
      ];

      clusterSetup.clusterProperties.marker_point_name = [
        "concat",
        ["concat", ["get", "Institute_Code_And_Point_Name"], ","]
      ];

      clusterSetup.clusterProperties.name = [
        "concat",

        ["concat", ["get", "Institute_Code_And_Point_Name"], ","]
      ];

      //this!!!
      clusterSetup.clusterProperties.marker_color_sets = [
        "concat",

        ["concat", ["get", "section_colour"], ","]
      ];

      clusterSetup.clusterProperties.icon_ids = [
        "concat",
        ["concat", ["get", "icon_id"], ","]
      ];
    }

    if (geoJson?.data) {
      parentThis.dataSouceIds[layerIdName] = layerIdName;
      parentThis.filterableDataSouceIds[layerIdName] = layerIdName;

      map.addSource(layerIdName, {
        type: "geojson",
        data: geoJson.data,

        ...clusterSetup
      });
    }

    let layerId_clustered = `${layerIdName}_clustered_layer`;
    let layerId_clustered_labels = `${layerIdName}_clustered_labels`;
    let layerId_clustered_screenreader = `${layerIdName}_clustered_screenreader`;

    let layerId_unclustered = `${layerIdName}_unclustered_layer`;
    let layerId_unclustered_labels = `${layerIdName}_unclustered_labels`;
    let layerId_unclustered_screenreader = `${layerIdName}_unclustered_screenreader`;

    if (hideClusteredLayer !== true) {
      parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;
      parentThis.pointLayerIds[layerId_clustered] = layerId_clustered;

      if (hoverState) {
        parentThis.hoverStatePointLayerIds[
          layerId_unclustered
        ] = layerId_unclustered;
      }

      if (haveToolTip) {
        parentThis.tooltipPointLayerIds[layerId_clustered] = layerId_clustered;
      }

      if (clusterOptions.useComboIcon === true) {
        parentThis.comboIconDataSoucesIds[layerIdName] = layerIdName;
      }

      if (hoverState && clusterOptions.useCircle) {
        parentThis.hoverStatePointLayerIds[
          layerId_clustered
        ] = layerId_clustered;
      }

      if (hasPopup != true) {
        parentThis.clusterLayersWithNoClick[
          layerId_clustered
        ] = layerId_clustered;
      }

      //codes
      //marker_color_sets = #165070,#165070,#165070,#165070,#165070,
      //it has all the colors in the cluster, then it dynamic looks for each of the colors, and uses the first one it finds as the cluster color;

      //filter will look like this:
      //let colorFilter =  ["case", ["in", "#165070", ["get", "marker_color_sets"]], "#165070", ["in", "#1b76a8", ["get", "marker_color_sets"]], "#1b76a8", "#1b76a8"];
      let colorFilter = ["case"];

      let colors = {};
      //this bit ensures that NSW is the first color;
      if (parentThis.categoryLookup.nsw) {
        colors["nsw"] = parentThis.categoryLookup.nsw.color;
      }
      for (const key in parentThis.categoryLookup) {
        if (!colors[key]) {
          colors[key] = parentThis.categoryLookup[key].color;
        }
      }
      let firstcolor = false;
      for (const key in colors) {
        let color = colors[key];
        if (!firstcolor) {
          firstcolor = color;
        }
        colorFilter.push(["in", color, ["get", "marker_color_sets"]]);
        colorFilter.push(color);
      }
      colorFilter.push(firstcolor);

      if (styles) {
        //do nothing!!
      }

      map.addLayer({
        id: layerId_clustered,
        type: "circle",
        source: layerIdName,
        filter: filter.clustered_layer,
        minzoom: scaling.minZoom,
        paint: {
          //...styles?.clustered_paint,

          "circle-color": colorFilter,
          "circle-opacity": clusterOptions.useCircle
            ? {
                stops: [
                  [scaling.clusterOpacityMinZoom, 0],
                  [scaling.clusterOpacityMaxZoom, 1]
                ]
              }
            : 0.01,
          "circle-radius": 20
        }
      });

      //lables
      if (clusterOptions.useCircle) {
        /*
        parentThis.pointLayerIds[
          layerId_clustered_labels
        ] = layerId_clustered_labels;
*/

        /*
        if (haveToolTip) {
          parentThis.tooltipPointLayerIds[
            layerId_clustered_labels
          ] = layerId_clustered_labels;
        }*/

        if (clusterLabel) {
          if (hoverState) {
            parentThis.hoverStatePointLayerIds[
              layerId_clustered_labels
            ] = layerId_clustered_labels;
          }
          map.addLayer({
            id: layerId_clustered_labels,
            type: "symbol",
            source: layerIdName,
            minzoom: scaling.minZoom,
            filter: filter.clustered_layer,

            paint: {
              "text-color": appSettings.styles.markerText,
              "text-opacity": {
                stops: [
                  [scaling.clusterOpacityMinZoom, 0],
                  [scaling.clusterOpacityMaxZoom, 1]
                ]
              }
            },
            layout: {
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 15,
              "text-field": "+",
              "text-anchor": "center"
            }
          });
        }
      }
    }

    let textField = showLabels
      ? { "text-field": ["get", "Site_Marker_Number_Label"] }
      : {};

    parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;

    if (hoverState) {
      parentThis.hoverStatePointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }
    if (haveToolTip) {
      parentThis.tooltipPointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }

    map.addLayer({
      id: layerId_unclustered,
      type: "circle",
      source: layerIdName,
      minzoom: scaling.minZoom,
      filter: filter.unclustered_layer,

      paint: {
        //...styles?.clustered_paint,
        "circle-color": ["get", "section_colour"],
        "circle-opacity": clusterOptions.useCircle
          ? {
              stops: [
                [scaling.clusterOpacityMinZoom, 0],
                [scaling.clusterOpacityMaxZoom, 1]
              ]
            }
          : 0.01,
        "circle-radius": 20
      }
    });

    if (showLabels) {
      /*
      parentThis.pointLayerIds[
        layerId_unclustered_labels
      ] = layerId_unclustered_labels;
*/
      map.addLayer({
        id: layerId_unclustered_labels,
        type: "symbol",
        source: layerIdName,
        minzoom: scaling.minZoom,
        filter: filter.unclustered_layer,

        paint: {
          "text-color": appSettings.styles.markerText,
          "text-opacity": {
            stops: [
              [scaling.opacityMinZoom, 0],
              [scaling.opacityMaxZoom, 1]
            ]
          }
        },
        layout: {
          "text-allow-overlap": false,
          "text-ignore-placement": false,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: [
              [13, 5],
              [18, 10],
              [19, 15],

              [21, 30]
            ]
            /*
            [13, 0.2 * scaling.unclusterScale],
            [18, 0.2 * scaling.unclusterScale],
            //[17, 0.3 * scaling.unclusterScale],
            [20, 1 * scaling.unclusterScale]
*/

            //getZoomScaleStops(2)
          },
          ...textField,
          "text-anchor": "bottom",
          "text-offset": [0, 0.4]
        }
      });
    }

    //screen reader fake layers!!
    if (screenReaderHotspot === true) {
      parentThis.screenReaderLayerIds[
        layerId_unclustered_screenreader
      ] = layerId_unclustered_screenreader;

      map.addLayer({
        id: layerId_unclustered_screenreader,
        type: "circle",
        source: layerIdName,
        minzoom: scaling.minZoom,
        filter: filter.unclustered_layer,

        paint: {
          //...styles?.clustered_paint,
          "circle-opacity": 0,
          "circle-color": "green",
          "circle-radius": 20
        }
      });

      if (hideClusteredLayer !== true) {
        parentThis.screenReaderLayerIds[
          layerId_clustered_screenreader
        ] = layerId_clustered_screenreader;

        map.addLayer({
          id: layerId_clustered_screenreader,
          type: "circle",
          source: layerIdName,
          filter: filter.clustered_layer,
          minzoom: scaling.minZoom,
          paint: {
            //...styles?.clustered_paint,
            "circle-opacity": 0,
            "circle-color": "red",
            "circle-radius": 30
          }
        });
      }
    }
  }
})();
