const targets = [
  {
    postId: "markers",
    taxonomies: ["markersection"],
    sectionTaxonomies: "markersection",
    listTaxonomies: "markersection",
    geoData: true
  },
  {
    postId: "artwork",
    taxonomies: ["artwork_section"],
    sectionTaxonomies: "artwork_section",
    geoData: true,
    forceMarkType: "artwork"
  }
];

module.exports = {
  targets
};
