//const turf = require("@turf/turf");

const zoomSettings = scale => [
  "interpolate",
  ["linear"],
  ["zoom"],
  5,
  0.01 * scale,
  9,
  0.2 * scale,
  12,
  0.5 * scale,
  15,
  2 * scale,
  20,
  3 * scale
];

const calculateBearing = (point1, point2) => {
  const lat1 = point1[1];
  const lon1 = point1[0];
  const lat2 = point2[1];
  const lon2 = point2[0];

  const dLon = lon2 - lon1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  let bearing = Math.atan2(y, x);

  // Convert bearing from radians to degrees
  bearing = (bearing * 180.0) / Math.PI;

  // Normalize the bearing to be between 0 and 360 degrees
  bearing = (bearing + 360) % 360;

  return bearing;
};

function addInterpolatedPoints(lineString, numPointsToAdd) {
  var coordinates = lineString.geometry.coordinates;
  var interpolatedCoordinates = [];

  for (var i = 0; i < coordinates.length - 1; i++) {
    var startPoint = coordinates[i];
    var endPoint = coordinates[i + 1];

    // Linear interpolation to calculate intermediate points
    for (var j = 0; j <= numPointsToAdd; j++) {
      var t = j / (numPointsToAdd + 1);
      var lng = startPoint[0] + t * (endPoint[0] - startPoint[0]);
      var lat = startPoint[1] + t * (endPoint[1] - startPoint[1]);
      interpolatedCoordinates.push([lng, lat]);
    }
  }

  // Update the LineString with interpolated coordinates
  lineString.geometry.coordinates = interpolatedCoordinates;
}

const animateMakerAlongAPath = (map, path, dataAndLayerId, options) => {
  if (path.type == "FeatureCollection") {
    path = path.features[0];

    //path.geometry.coordinates.reverse();
  }
  //add extra inbetween points;

  let factor = 30 / (options?.speed ? options.speed : 1);

  addInterpolatedPoints(path, factor);

  let point = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: path.geometry.coordinates[0]
        }
      }
    ]
  };

  map.addSource(dataAndLayerId, {
    type: "geojson",
    data: point
  });
  map.getSource(dataAndLayerId).setData(point);

  map.addLayer({
    id: dataAndLayerId,
    source: dataAndLayerId,
    type: "symbol",
    layout: {
      "icon-image": `${options?.markerAssetTitle}`,
      "icon-size": zoomSettings(options?.scale ? options?.scale : 1),
      "icon-rotate": ["get", "bearing"],
      "icon-rotation-alignment": "map",
      "icon-allow-overlap": true,
      "icon-ignore-placement": true
    }
  });

  let interval = options?.interval ? options?.interval : 200;
  var i = 1;

  const animateMarker = () => {
    //marker.setLngLat(path.geometry.coordinates[0]);
    setInterval(function() {
      if (i < path.geometry.coordinates.length) {
        //marker.setLngLat(path.geometry.coordinates[i]);

        let bearing = calculateBearing(
          point.features[0].geometry.coordinates,
          path?.geometry?.coordinates[i]
        );
        point.features[0].geometry.coordinates = path?.geometry?.coordinates[i];

        point.features[0].properties.bearing = 90 + bearing; //-83.01791149252307; //90 + turf.bearing(turf.point(start), turf.point(end));

        try {
          map.getSource(dataAndLayerId).setData(point);
        } catch (e) {
          //do nothing;
        }
        // Update the source with this new data

        i++;
      }

      if (i * 1.2 > path.geometry.coordinates.length) {
        //reset;
        i = 0;
      }
    }, interval); // Adjust the interval for the animation speed
  };

  animateMarker();
};

export default new (class {
  async drawBoat({ parentThis, map, appSettings }) {
    if (parentThis && map && appSettings) {
      //do nothing;
    }

    (async () => {
      const dataAndLayerId = "ferryOneBigPoint";
      const markerAssetTitle = "187";
      const options = { markerAssetTitle };

      let path = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [
                [144.36918883045337, -38.10696992555111],
                [144.3751249713883, -38.10865662415226],
                [144.38106111232327, -38.11209477377011],
                [144.38823394928608, -38.11683007337683],
                [144.39771528549937, -38.120073252108064],
                [144.41222585222863, -38.12033270018384],
                [144.42953959662094, -38.11877599789994],
                [144.4475129122294, -38.11644088225011],
                [144.46936103191007, -38.113456951900126],
                [144.49549638173312, -38.10956474649269],
                [144.52270369435035, -38.10560747977788]
              ],
              type: "LineString"
            }
          }
        ]
      };

      animateMakerAlongAPath(map, path, dataAndLayerId, options);
    })();

    (async () => {
      const dataAndLayerId = "ferryTwoSmallPoint";
      const markerAssetTitle = "187";
      const options = { markerAssetTitle, scale: 0.4, speed: 2 };
      const MOVE_SmallFerryPath = require("./geom/move/MOVE_SmallFerryPath.json");

      MOVE_SmallFerryPath.features = MOVE_SmallFerryPath.features.filter(
        feature => feature.geometry.type === "LineString"
      );

      let path = MOVE_SmallFerryPath;

      animateMakerAlongAPath(map, path, dataAndLayerId, options);
    })();

    (async () => {
      const dataAndLayerId = "ferryThreeSmallPoint";
      const markerAssetTitle = "187";
      const options = { markerAssetTitle, scale: 0.4, speed: 2 };
      const MOVE_SmallFerryReversePath = require("./geom/move/MOVE_SmallFerryReversePath.json");

      MOVE_SmallFerryReversePath.features = MOVE_SmallFerryReversePath.features.filter(
        feature => feature.geometry.type === "LineString"
      );

      let path = MOVE_SmallFerryReversePath;

      animateMakerAlongAPath(map, path, dataAndLayerId, options);
    })();

    (async () => {
      const dataAndLayerId = "flightPath";
      const markerAssetTitle = "168";
      const options = { markerAssetTitle, scale: 1, speed: 2 };
      const MOVEFLIGHTPATH = require("./geom/move/MOVEFLIGHTPATH.json");

      MOVEFLIGHTPATH.features = MOVEFLIGHTPATH.features.filter(
        feature => feature.geometry.type === "LineString"
      );

      let path = MOVEFLIGHTPATH;

      animateMakerAlongAPath(map, path, dataAndLayerId, options);
    })();
  }

  async drawPlane({ parentThis, map, appSettings }) {
    if (parentThis && map && appSettings) {
      //do nothing;
    }

    // San Francisco
    //const origin =[144.454, -38.118];
    //const destination =  [144.469, -38.027];

    //    const origin = [144.48270823001985, -37.939131418785074];
    //  const //[144.46480484806233, -38.05442628607689];

    const origin = [144.48270823001985, -37.939131418785074];
    const destination = [144.46480484806233, -38.05442628607689];

    // A simple line from origin to destination.
    const route = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [origin, destination]
          }
        }
      ]
    };
    const point = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: origin
          }
        }
      ]
    };

    const lineDistance = 12.915853525703879; //turf.length(route.features[0]);

    if (lineDistance) {
      //do nothinkg;
    }

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    const steps = 500;
    //const arc = [];

    // Draw an arc between the `origin` & `destination` of the two points
    /*for (let i = 0; i < lineDistance; i += lineDistance / steps) {
      const segment = turf.along(route.features[0], i);
      arc.push(segment.geometry.coordinates);
    }
*/
    const arc = [
      [144.48270823001985, -37.939131418785074],
      [144.48267247936315, -37.939362011224944],
      [144.48263672848216, -37.939592603654006],
      [144.4826009773768, -37.93982319607225],
      [144.4825652260471, -37.94005378847968],
      [144.48252947449302, -37.940284380876285],
      [144.48249372271465, -37.940514973262076],
      [144.4824579707119, -37.940745565637044],
      [144.48242221848477, -37.94097615800121],
      [144.48238646603332, -37.941206750354546],
      [144.4823507133575, -37.941437342697064],
      [144.4823149604573, -37.94166793502877],
      [144.48227920733274, -37.941898527349665],
      [144.4822434539838, -37.94212911965972],
      [144.4822077004105, -37.94235971195897],
      [144.4821719466128, -37.9425903042474],
      [144.48213619259073, -37.94282089652501],
      [144.48210043834428, -37.9430514887918],
      [144.4820646838734, -37.943282081047776],
      [144.48202892917817, -37.94351267329292],
      [144.48199317425855, -37.94374326552726],
      [144.4819574191145, -37.94397385775078],
      [144.48192166374608, -37.94420444996347],
      [144.48188590815326, -37.944435042165345],
      [144.481850152336, -37.9446656343564],
      [144.48181439629437, -37.944896226536635],
      [144.4817786400283, -37.94512681870605],
      [144.4817428835378, -37.94535741086463],
      [144.48170712682287, -37.94558800301241],
      [144.48167136988354, -37.94581859514936],
      [144.48163561271977, -37.94604918727549],
      [144.48159985533158, -37.9462797793908],
      [144.48156409771894, -37.946510371495286],
      [144.4815283398819, -37.94674096358895],
      [144.4814925818204, -37.9469715556718],
      [144.48145682353442, -37.94720214774382],
      [144.48142106502402, -37.94743273980502],
      [144.48138530628916, -37.9476633318554],
      [144.48134954732987, -37.94789392389496],
      [144.4813137881461, -37.94812451592369],
      [144.48127802873788, -37.94835510794161],
      [144.4812422691052, -37.948585699948694],
      [144.48120650924807, -37.94881629194495],
      [144.4811707491664, -37.9490468839304],
      [144.48113498886033, -37.94927747590501],
      [144.48109922832975, -37.949508067868805],
      [144.4810634675747, -37.94973865982179],
      [144.48102770659517, -37.94996925176392],
      [144.48099194539114, -37.95019984369525],
      [144.48095618396263, -37.95043043561575],
      [144.48092042230962, -37.950661027525435],
      [144.48088466043214, -37.95089161942428],
      [144.48084889833012, -37.95112221131231],
      [144.48081313600363, -37.951352803189515],
      [144.4807773734526, -37.951583395055884],
      [144.4807416106771, -37.951813986911446],
      [144.4807058476771, -37.95204457875617],
      [144.4806700844525, -37.95227517059008],
      [144.48063432100344, -37.952505762413146],
      [144.48059855732987, -37.95273635422541],
      [144.48056279343174, -37.95296694602683],
      [144.48052702930912, -37.95319753781743],
      [144.4804912649619, -37.9534281295972],
      [144.4804555003902, -37.95365872136614],
      [144.48041973559396, -37.953889313124265],
      [144.48038397057317, -37.95411990487156],
      [144.48034820532783, -37.95435049660802],
      [144.48031243985795, -37.954581088333676],
      [144.48027667416352, -37.954811680048486],
      [144.48024090824453, -37.95504227175247],
      [144.48020514210097, -37.955272863445636],
      [144.48016937573286, -37.95550345512796],
      [144.48013360914018, -37.95573404679946],
      [144.48009784232292, -37.955964638460145],
      [144.48006207528113, -37.956195230109984],
      [144.48002630801474, -37.956425821749015],
      [144.47999054052374, -37.9566564133772],
      [144.47995477280818, -37.95688700499456],
      [144.47991900486807, -37.9571175966011],
      [144.47988323670336, -37.957348188196804],
      [144.47984746831403, -37.95757877978167],
      [144.47981169970015, -37.95780937135573],
      [144.47977593086162, -37.95803996291895],
      [144.47974016179853, -37.95827055447133],
      [144.4797043925108, -37.95850114601289],
      [144.47966862299847, -37.95873173754363],
      [144.47963285326156, -37.958962329063525],
      [144.47959708330004, -37.95919292057259],
      [144.47956131311386, -37.95942351207083],
      [144.47952554270307, -37.959654103558236],
      [144.4794897720677, -37.95988469503482],
      [144.47945400120764, -37.96011528650057],
      [144.47941823012297, -37.96034587795548],
      [144.47938245881372, -37.96057646939958],
      [144.4793466872798, -37.960807060832835],
      [144.4793109155212, -37.96103765225524],
      [144.479275143538, -37.961268243666844],
      [144.47923937133012, -37.9614988350676],
      [144.47920359889764, -37.961729426457524],
      [144.47916782624046, -37.96196001783663],
      [144.47913205335868, -37.96219060920489],
      [144.47909628025218, -37.96242120056233],
      [144.47906050692103, -37.962651791908925],
      [144.47902473336524, -37.96288238324469],
      [144.47898895958477, -37.96311297456963],
      [144.47895318557963, -37.96334356588373],
      [144.4789174113498, -37.96357415718701],
      [144.4788816368953, -37.96380474847943],
      [144.4788458622161, -37.96403533976103],
      [144.47881008731224, -37.96426593103181],
      [144.4787743121837, -37.96449652229174],
      [144.4787385368304, -37.964727113540846],
      [144.4787027612525, -37.96495770477911],
      [144.47866698544985, -37.96518829600654],
      [144.47863120942247, -37.965418887223144],
      [144.47859543317043, -37.9656494784289],
      [144.47855965669368, -37.965880069623836],
      [144.4785238799922, -37.96611066080793],
      [144.478488103066, -37.9663412519812],
      [144.47845232591513, -37.96657184314362],
      [144.47841654853949, -37.966802434295204],
      [144.4783807709391, -37.96703302543596],
      [144.47834499311406, -37.96726361656588],
      [144.47830921506426, -37.967494207684965],
      [144.4782734367897, -37.96772479879321],
      [144.47823765829042, -37.967955389890626],
      [144.4782018795664, -37.968185980977196],
      [144.47816610061764, -37.96841657205294],
      [144.47813032144413, -37.96864716311784],
      [144.47809454204585, -37.9688777541719],
      [144.47805876242282, -37.96910834521513],
      [144.47802298257506, -37.96933893624752],
      [144.4779872025025, -37.96956952726908],
      [144.47795142220522, -37.96980011827979],
      [144.47791564168313, -37.97003070927967],
      [144.47787986093633, -37.97026130026872],
      [144.47784407996468, -37.97049189124692],
      [144.47780829876828, -37.970722482214285],
      [144.47777251734712, -37.97095307317082],
      [144.4777367357012, -37.9711836641165],
      [144.47770095383044, -37.97141425505135],
      [144.47766517173488, -37.97164484597537],
      [144.47762938941457, -37.97187543688854],
      [144.47759360686945, -37.97210602779088],
      [144.47755782409953, -37.97233661868238],
      [144.4775220411048, -37.97256720956304],
      [144.47748625788526, -37.97279780043285],
      [144.47745047444096, -37.97302839129182],
      [144.47741469077175, -37.97325898213997],
      [144.47737890687782, -37.973489572977265],
      [144.477343122759, -37.97372016380372],
      [144.4773073384154, -37.973950754619345],
      [144.47727155384695, -37.974181345424114],
      [144.4772357690537, -37.974411936218054],
      [144.4771999840356, -37.97464252700115],
      [144.47716419879265, -37.97487311777341],
      [144.4771284133249, -37.97510370853482],
      [144.47709262763226, -37.97533429928539],
      [144.4770568417148, -37.97556489002513],
      [144.4770210555725, -37.97579548075402],
      [144.47698526920533, -37.97602607147208],
      [144.47694948261332, -37.97625666217928],
      [144.47691369579644, -37.97648725287566],
      [144.4768779087547, -37.97671784356117],
      [144.4768421214881, -37.97694843423586],
      [144.4768063339966, -37.97717902489969],
      [144.47677054628028, -37.9774096155527],
      [144.47673475833903, -37.97764020619485],
      [144.47669897017295, -37.97787079682616],
      [144.47666318178196, -37.97810138744663],
      [144.4766273931661, -37.97833197805626],
      [144.47659160432534, -37.97856256865503],
      [144.47655581525967, -37.97879315924297],
      [144.47652002596917, -37.979023749820065],
      [144.4764842364537, -37.97925434038631],
      [144.4764484467134, -37.97948493094172],
      [144.47641265674812, -37.9797155214863],
      [144.47637686655798, -37.979946112020016],
      [144.47634107614292, -37.9801767025429],
      [144.47630528550295, -37.98040729305491],
      [144.47626949463807, -37.98063788355609],
      [144.47623370354825, -37.98086847404644],
      [144.4761979122335, -37.98109906452594],
      [144.47616212069383, -37.98132965499459],
      [144.47612632892924, -37.98156024545239],
      [144.4760905369397, -37.98179083589936],
      [144.47605474472525, -37.982021426335464],
      [144.47601895228584, -37.98225201676074],
      [144.47598315962148, -37.98248260717515],
      [144.47594736673219, -37.98271319757873],
      [144.47591157361794, -37.98294378797146],
      [144.47587578027873, -37.98317437835335],
      [144.4758399867146, -37.983404968724386],
      [144.47580419292547, -37.98363555908457],
      [144.47576839891136, -37.983866149433915],
      [144.47573260467232, -37.98409673977241],
      [144.4756968102083, -37.98432733010006],
      [144.4756610155193, -37.98455792041686],
      [144.47562522060534, -37.984788510722815],
      [144.47558942546638, -37.985019101017905],
      [144.47555363010247, -37.985249691302165],
      [144.47551783451354, -37.985480281575576],
      [144.47548203869962, -37.985710871838144],
      [144.47544624266072, -37.985941462089855],
      [144.47541044639684, -37.986172052330716],
      [144.47537464990796, -37.98640264256073],
      [144.47533885319407, -37.986633232779894],
      [144.47530305625514, -37.98686382298823],
      [144.47526725909123, -37.98709441318569],
      [144.47523146170232, -37.98732500337232],
      [144.47519566408837, -37.98755559354808],
      [144.47515986624938, -37.98778618371299],
      [144.47512406818544, -37.98801677386707],
      [144.47508826989642, -37.988247364010284],
      [144.47505247138238, -37.98847795414266],
      [144.4750166726433, -37.98870854426418],
      [144.4749808736792, -37.98893913437486],
      [144.47494507449008, -37.989169724474664],
      [144.4749092750759, -37.98940031456364],
      [144.47487347543665, -37.98963090464175],
      [144.4748376755724, -37.98986149470902],
      [144.47480187548308, -37.990092084765436],
      [144.47476607516867, -37.990322674811004],
      [144.47473027462925, -37.990553264845715],
      [144.47469447386473, -37.990783854869576],
      [144.47465867287517, -37.99101444488257],
      [144.47462287166053, -37.991245034884734],
      [144.47458707022082, -37.99147562487604],
      [144.47455126855604, -37.99170621485649],
      [144.4745154666662, -37.99193680482609],
      [144.47447966455127, -37.99216739478483],
      [144.47444386221122, -37.99239798473273],
      [144.4744080596461, -37.99262857466977],
      [144.4743722568559, -37.99285916459596],
      [144.47433645384064, -37.9930897545113],
      [144.4743006506002, -37.99332034441578],
      [144.47426484713472, -37.993550934309404],
      [144.4742290434441, -37.99378152419218],
      [144.47419323952843, -37.9940121140641],
      [144.4741574353876, -37.99424270392517],
      [144.4741216310217, -37.99447329377538],
      [144.4740858264306, -37.99470388361474],
      [144.47405002161443, -37.99493447344324],
      [144.47401421657315, -37.995165063260885],
      [144.47397841130675, -37.995395653067675],
      [144.4739426058152, -37.995626242863615],
      [144.47390680009852, -37.9958568326487],
      [144.4738709941567, -37.996087422422924],
      [144.47383518798972, -37.9963180121863],
      [144.47379938159764, -37.99654860193882],
      [144.47376357498038, -37.996779191680474],
      [144.473727768138, -37.997009781411286],
      [144.47369196107041, -37.99724037113123],
      [144.47365615377774, -37.99747096084032],
      [144.47362034625988, -37.99770155053856],
      [144.47358453851683, -37.99793214022593],
      [144.47354873054863, -37.99816272990246],
      [144.47351292235527, -37.99839331956812],
      [144.47347711393672, -37.998623909222935],
      [144.47344130529302, -37.998854498866876],
      [144.47340549642414, -37.99908508849998],
      [144.47336968733006, -37.99931567812222],
      [144.47333387801078, -37.99954626773359],
      [144.47329806846633, -37.99977685733412],
      [144.47326225869668, -38.000007446923775],
      [144.47322644870187, -38.00023803650258],
      [144.47319063848184, -38.00046862607053],
      [144.4731548280366, -38.00069921562762],
      [144.47311901736614, -38.00092980517385],
      [144.47308320647048, -38.00116039470921],
      [144.47304739534962, -38.00139098423372],
      [144.47301158400353, -38.00162157374738],
      [144.47297577243225, -38.00185216325016],
      [144.47293996063573, -38.00208275274211],
      [144.472904148614, -38.00231334222318],
      [144.472868336367, -38.00254393169339],
      [144.4728325238948, -38.00277452115275],
      [144.47279671119736, -38.00300511060124],
      [144.4727608982747, -38.003235700038886],
      [144.47272508512677, -38.00346628946564],
      [144.4726892717536, -38.003696878881556],
      [144.4726534581552, -38.003927468286605],
      [144.47261764433154, -38.004158057680804],
      [144.4725818302826, -38.00438864706414],
      [144.47254601600844, -38.00461923643661],
      [144.47251020150898, -38.00484982579821],
      [144.47247438678428, -38.00508041514895],
      [144.47243857183432, -38.005311004488846],
      [144.47240275665908, -38.00554159381787],
      [144.47236694125857, -38.005772183136024],
      [144.47233112563276, -38.00600277244332],
      [144.47229530978169, -38.00623336173977],
      [144.47225949370534, -38.006463951025324],
      [144.47222367740372, -38.00669454030005],
      [144.47218786087677, -38.006925129563896],
      [144.47215204412453, -38.007155718816875],
      [144.472116227147, -38.00738630805901],
      [144.4720804099442, -38.00761689729025],
      [144.47204459251606, -38.00784748651066],
      [144.47200877486262, -38.00807807572018],
      [144.47197295698388, -38.00830866491885],
      [144.4719371388798, -38.00853925410665],
      [144.47190132055044, -38.0087698432836],
      [144.47186550199575, -38.00900043244968],
      [144.47182968321573, -38.00923102160489],
      [144.47179386421035, -38.00946161074924],
      [144.47175804497968, -38.00969219988272],
      [144.47172222552368, -38.00992278900533],
      [144.47168640584235, -38.01015337811709],
      [144.47165058593566, -38.01038396721797],
      [144.47161476580362, -38.010614556308],
      [144.47157894544625, -38.01084514538716],
      [144.4715431248635, -38.011075734455446],
      [144.47150730405542, -38.01130632351287],
      [144.471471483022, -38.01153691255943],
      [144.4714356617632, -38.01176750159512],
      [144.47139984027905, -38.01199809061995],
      [144.47136401856952, -38.012228679633914],
      [144.47132819663463, -38.012459268637],
      [144.4712923744744, -38.01268985762923],
      [144.47125655208873, -38.01292044661059],
      [144.47122072947772, -38.01315103558109],
      [144.47118490664133, -38.01338162454071],
      [144.47114908357955, -38.01361221348946],
      [144.47111326029236, -38.013842802427355],
      [144.4710774367798, -38.014073391354394],
      [144.47104161304185, -38.01430398027054],
      [144.47100578907848, -38.01453456917583],
      [144.47096996488972, -38.01476515807024],
      [144.47093414047552, -38.0149957469538],
      [144.47089831583597, -38.01522633582648],
      [144.47086249097097, -38.015456924688294],
      [144.47082666588057, -38.01568751353924],
      [144.47079084056475, -38.01591810237932],
      [144.4707550150235, -38.016148691208514],
      [144.47071918925684, -38.01637928002687],
      [144.47068336326473, -38.01660986883433],
      [144.47064753704723, -38.01684045763093],
      [144.47061171060426, -38.01707104641666],
      [144.47057588393585, -38.017301635191515],
      [144.47054005704203, -38.0175322239555],
      [144.47050422992274, -38.017762812708625],
      [144.47046840257798, -38.01799340145087],
      [144.4704325750078, -38.01822399018225],
      [144.47039674721216, -38.01845457890276],
      [144.47036091919105, -38.0186851676124],
      [144.47032509094453, -38.01891575631116],
      [144.4702892624725, -38.01914634499905],
      [144.47025343377499, -38.01937693367608],
      [144.47021760485202, -38.01960752234224],
      [144.4701817757036, -38.01983811099751],
      [144.4701459463297, -38.02006869964191],
      [144.4701101167303, -38.02029928827545],
      [144.47007428690543, -38.02052987689812],
      [144.47003845685504, -38.0207604655099],
      [144.4700026265792, -38.02099105411082],
      [144.46996679607787, -38.021221642700866],
      [144.469930965351, -38.021452231280044],
      [144.46989513439868, -38.02168281984834],
      [144.46985930322083, -38.02191340840577],
      [144.46982347181745, -38.02214399695233],
      [144.4697876401886, -38.022374585488016],
      [144.4697518083342, -38.02260517401282],
      [144.46971597625435, -38.022835762526746],
      [144.4696801439489, -38.023066351029804],
      [144.46964431141797, -38.02329693952199],
      [144.4696084786615, -38.02352752800331],
      [144.4695726456795, -38.02375811647374],
      [144.46953681247197, -38.023988704933316],
      [144.4695009790389, -38.024219293382],
      [144.4694651453803, -38.02444988181981],
      [144.46942931149613, -38.02468047024675],
      [144.46939347738646, -38.0249110586628],
      [144.4693576430512, -38.025141647068004],
      [144.4693218084904, -38.025372235462314],
      [144.46928597370407, -38.02560282384575],
      [144.46925013869216, -38.0258334122183],
      [144.4692143034547, -38.02606400057999],
      [144.46917846799164, -38.0262945889308],
      [144.46914263230303, -38.026525177270734],
      [144.46910679638884, -38.026755765599795],
      [144.4690709602491, -38.02698635391798],
      [144.46903512388377, -38.02721694222527],
      [144.46899928729286, -38.0274475305217],
      [144.46896345047634, -38.02767811880724],
      [144.46892761343423, -38.02790870708193],
      [144.46889177616657, -38.02813929534572],
      [144.46885593867327, -38.028369883598636],
      [144.46882010095442, -38.02860047184066],
      [144.46878426300992, -38.02883106007183],
      [144.46874842483984, -38.029061648292114],
      [144.46871258644416, -38.029292236501526],
      [144.46867674782283, -38.029522824700045],
      [144.4686409089759, -38.0297534128877],
      [144.4686050699034, -38.02998400106446],
      [144.46856923060523, -38.030214589230354],
      [144.46853339108145, -38.03044517738537],
      [144.468497551332, -38.030675765529494],
      [144.46846171135698, -38.03090635366275],
      [144.46842587115628, -38.03113694178513],
      [144.46839003072998, -38.03136752989662],
      [144.468354190078, -38.031598117997234],
      [144.46831834920042, -38.03182870608697],
      [144.46828250809713, -38.032059294165826],
      [144.46824666676824, -38.032289882233805],
      [144.4682108252137, -38.03252047029089],
      [144.46817498343347, -38.03275105833711],
      [144.4681391414276, -38.03298164637244],
      [144.46810329919606, -38.03321223439689],
      [144.46806745673885, -38.033442822410464],
      [144.46803161405597, -38.03367341041315],
      [144.46799577114743, -38.033903998404966],
      [144.4679599280132, -38.03413458638589],
      [144.46792408465328, -38.03436517435594],
      [144.46788824106767, -38.03459576231511],
      [144.4678523972564, -38.03482635026338],
      [144.4678165532194, -38.035056938200775],
      [144.46778070895675, -38.03528752612729],
      [144.4677448644684, -38.03551811404294],
      [144.46770901975432, -38.03574870194769],
      [144.46767317481454, -38.035979289841556],
      [144.46763732964905, -38.036209877724545],
      [144.46760148425787, -38.03644046559664],
      [144.467565638641, -38.03667105345787],
      [144.46752979279836, -38.03690164130821],
      [144.46749394673003, -38.03713222914766],
      [144.46745810043598, -38.03736281697622],
      [144.46742225391617, -38.03759340479392],
      [144.46738640717066, -38.037823992600714],
      [144.4673505601994, -38.03805458039663],
      [144.46731471300242, -38.03828516818165],
      [144.46727886557971, -38.03851575595581],
      [144.46724301793122, -38.038746343719076],
      [144.46720717005704, -38.03897693147146],
      [144.46717132195707, -38.039207519212944],
      [144.46713547363134, -38.03943810694356],
      [144.46709962507987, -38.03966869466329],
      [144.4670637763026, -38.03989928237212],
      [144.46702792729965, -38.04012987007007],
      [144.46699207807086, -38.040360457757146],
      [144.46695622861634, -38.04059104543332],
      [144.466920378936, -38.040821633098616],
      [144.46688452902995, -38.04105222075302],
      [144.46684867889812, -38.04128280839655],
      [144.46681282854044, -38.04151339602918],
      [144.46677697795704, -38.04174398365093],
      [144.4667411271478, -38.0419745712618],
      [144.4667052761128, -38.04220515886176],
      [144.46666942485197, -38.042435746450856],
      [144.46663357336539, -38.04266633402906],
      [144.466597721653, -38.042896921596366],
      [144.46656186971478, -38.04312750915278],
      [144.46652601755076, -38.043358096698334],
      [144.4664901651609, -38.04358868423297],
      [144.46645431254527, -38.04381927175673],
      [144.46641845970382, -38.04404985926961],
      [144.46638260663653, -38.044280446771594],
      [144.46634675334343, -38.0445110342627],
      [144.46631089982444, -38.04474162174289],
      [144.46627504607972, -38.044972209212226],
      [144.46623919210907, -38.04520279667064],
      [144.46620333791265, -38.045433384118176],
      [144.46616748349035, -38.04566397155483],
      [144.46613162884222, -38.04589455898059],
      [144.46609577396825, -38.04612514639546],
      [144.46605991886838, -38.04635573379944],
      [144.4660240635427, -38.046586321192535],
      [144.46598820799116, -38.04681690857473],
      [144.46595235221375, -38.04704749594604],
      [144.4659164962105, -38.047278083306466],
      [144.46588063998135, -38.047508670656],
      [144.46584478352634, -38.04773925799463],
      [144.46580892684545, -38.04796984532238],
      [144.4657730699387, -38.048200432639234],
      [144.46573721280603, -38.04843101994519],
      [144.46570135544752, -38.04866160724026],
      [144.46566549786309, -38.04889219452445],
      [144.4656296400528, -38.04912278179774],
      [144.4655937820166, -38.049353369060135],
      [144.46555792375452, -38.04958395631164],
      [144.4655220652665, -38.049814543552266],
      [144.4654862065526, -38.050045130781974],
      [144.4654503476128, -38.050275718000805],
      [144.46541448844707, -38.05050630520874],
      [144.46537862905544, -38.05073689240579],
      [144.46534276943788, -38.050967479591925],
      [144.4653069095944, -38.051198066767185],
      [144.46527104952503, -38.05142865393155],
      [144.4652351892297, -38.05165924108502],
      [144.46519932870845, -38.051889828227594],
      [144.46516346796125, -38.052120415359276],
      [144.46512760698815, -38.05235100248006],
      [144.46509174578904, -38.052581589589956],
      [144.46505588436406, -38.05281217668895],
      [144.4650200227131, -38.05304276377705],
      [144.4649841608362, -38.05327335085427],
      [144.46494829873333, -38.05350393792058],
      [144.46491243640452, -38.053734524976],
      [144.46487657384975, -38.05396511202053],
      [144.46484071106903, -38.05419569905416]
    ];

    // Update the route with calculated arc coordinates
    route.features[0].geometry.coordinates = arc;

    // Used to increment the value of the point measurement against the route.
    let counter = 0;

    // Add a source and layer displaying a point which will be animated in a circle.
    map.addSource("route", {
      type: "geojson",
      data: route
    });

    map.addSource("jetPoint", {
      type: "geojson",
      data: point
    });

    map.addLayer({
      id: "jetPoint",
      source: "jetPoint",
      type: "symbol",
      layout: {
        "icon-image": "168",
        "icon-size": zoomSettings(1),
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      }
    });
    var running = false;

    if (running) {
      //do ntohing;
    }

    function animate() {
      running = true;
      const start =
        route.features[0].geometry.coordinates[
          counter >= steps ? counter - 1 : counter
        ];
      const end =
        route.features[0].geometry.coordinates[
          counter >= steps ? counter : counter + 1
        ];

      if (!start || !end) {
        running = false;
        //document.getElementById("replaydisabled = false;

        setTimeout(() => {
          counter = 0;
          animate(counter);
        }, 6000);

        return;
      }
      // Update point geometry to a new position based on counter denoting
      // the index to access the arc
      point.features[0].geometry.coordinates =
        route.features[0].geometry.coordinates[counter];

      point.features[0].properties.bearing = -83.01791149252307; //90 + turf.bearing(turf.point(start), turf.point(end));

      // Update the source with this new data
      map.getSource("jetPoint").setData(point);

      // Request the next frame of animation as long as the end has not been reached
      if (counter < steps) {
        requestAnimationFrame(animate);
      }

      counter = counter + 1;
    }

    animate(counter);
  }
})();
