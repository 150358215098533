<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          ><font v-if="!$vuetify.breakpoint.xsOnly">
            Acknowledgement of Country
          </font>
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="false"
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          {{ title }}
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="6"
            class="pa-5 imageWrapper pt-16"
            v-show="$vuetify.breakpoint.mdAndUp"
          >
            <br />
            <v-img
              class="white--text align-end  ma-0"
              src="/assets/landingPages/LfG_FNPCEPP_Bunjil_with_Sun_and_Moon_small.jpg"
            >
            </v-img>
          </v-col>

          <v-col cols="12" md="6" class="pt-3 pb-0 imageWrapper">
            <center>
              <v-card-title class="justify-center"
                >Acknowledgement of Country
              </v-card-title>
              <v-card-text>
                The Committee for Geelong acknowledges the Wadawurrung People as
                the Traditional Owners of the lands, waters, seas and skies on
                which we operate. We pay our respects to their Elders past and
                present. We acknowledge Aboriginal and Torres Strait Islander
                people as Australia’s First Nations people. We commit ourselves
                to working for reconciliation with First Nations People and
                supporting them in having a voice.
              </v-card-text>
              <v-img
                v-show="!$vuetify.breakpoint.mdAndUp"
                style="max-width:50%;    border-radius: 4px;"
                class="white--text align-end  ma-0"
                src="/assets/landingPages/LfG_FNPCEPP_Bunjil_with_Sun_and_Moon_small.jpg"
              />
              <v-card-subtitle class="justify-center">
                Artwork by Ammie Howell
              </v-card-subtitle>
            </center>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      dontShowAgain: false,
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowAcknowedgement", this.dontShowAgain);
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }
      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  created() {
    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  beforeDestroy() {
    //do something before destroying vue instance
    eventBus.$off("loadIntroPopup");
  },

  mounted() {
    var bootOption = false;
    if (this?.appSettings?.allowSkipWelcomeDialog === true) {
      bootOption = localStorage.getItem("dontShowAcknowedgement");
      if (bootOption == true || bootOption == "true") {
        bootOption = true;
      }
      if (bootOption === true) {
        this.dontShowAgain = true;
      }
    }

    setTimeout(() => {
      if (bootOption != true) {
        this.openIntro = true;
      }
    }, 300);
  }
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .noOverflowOnWide {
    overflow: hidden;
  }

  .v-application div.bodyTextAck {
    margin-bottom: -18px !important;
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 959px) {
  .v-application div.bodyTextAck {
    padding-top: 0 !important;
  }

  .noOverflowOnWide {
    margin: 0 !important;
  }
}
</style>
